


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import xss from 'xss';
import Vue from 'vue';
import UUID from 'uuid';
import { ElUploadInternalFileDetail } from 'element-ui/types/upload';
import moment from 'moment';
// import TopBg from '../fair/component/detailTopBg.vue';
// import Invoice from './component/invoice.vue';
import { mapState } from 'vuex';
import nepcon from './formData/nepcon';
import { ResponseCode } from '~/util/constant';
// import AsideFair from '~/components/AsideFair/index.vue';
import {
  getContent,
  checkEmail,
  message,
  uniqueArray,
  bucketCdnHost,
  bucketHost,
} from '~/util/common';
import SingleUpload from '~/components/SingleUpload/index.vue';
import hesuan from '~/util/hesuan';
import payTicketMixin from '~/pages/pay/pay-ticket-mixin';
import { TraceCode } from '~/util/trace';
import JuMap from '~/components/Map/index.vue';

// import {
//   bucketCdnHost,
//   bucketHost,
//   // getContent,
//   // MINI_HOST,
//   // sleep,
// } from '~/util/common';

const _visitor = {
  visitorName: '',
  // visitorNameEn: '',
  sex: '',
  mobile: '',
  email: '',
  cardType: 1, // 1身份证 2护照 3港澳居民来往内地通行证 4台湾居民来往内地通行证
  visitorIdentity: '',
  post: '',
  userCertificates: '', // 证件照
  identityCardFront: '',
  identityCardBack: '',
  department: '', // 部门
  companyTypes: [], // 公司所属类型
  companyService: [], // 公司所属类型
  products: [], // 公司所属类型
};

export default Vue.extend({
  layout: 'layout2',
  components: {
    // AsideFair,
    // Invoice,
    SingleUpload,
    // TopBg,
    JuMap,
  },
  mixins: [payTicketMixin],
  async asyncData(ctx) {
    const id = _.get(ctx, 'params.id', null);
    const res: any[] = await Promise.all([
      ctx.$axios.get(`/api/exhibition/${id}`),
      ctx.$axios.get(`/api/ticket/query/${id}`),
      ctx.$axios.post(`/api/visitor/list-by-current`),
      // ctx.$axios.get(`/api/share/queryShareTicket/${id}`),
    ]);
    const [fairRes, priceRes, visitorRes] = res;

    // let fair: Record<string, any> = {};
    let fair: any = {};
    const industry: any[] = [];
    let industryId: any[] = [];
    let industryName: any[] = [];
    if (fairRes.code === ResponseCode.SUCCESS) {
      fair = fairRes.data || {};
      industryId = [fair.industry1, fair.industry2, fair.industry3].filter(
        (id) => !!id
      );
      industryName = fair.industry?.split(',') || [];
      industryName?.forEach((name, index) => {
        industry.push({
          id: industryId[index],
          name,
        });
      });
      
      // online 1:上架 2:未上架
      if (
        (!_.isNil(fair.online) && fair.online !== 1 && id !== '4') ||
        _.isNil(fairRes.data)
      ) {
        return ctx.error({
          message: 'Page not found',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }
    }

    let ticketPrice: Record<string, any> = {};
    let otherTicketList: any = [];
    if (priceRes.code === ResponseCode.SUCCESS) {
      ticketPrice = priceRes.data || {};
      if (ticketPrice.startSaleDate && ticketPrice.endSaleDate) {
        let currentTimestamp = new Date().getTime();
        let startTime = new Date(
          `${ticketPrice.startSaleDate} 00:00:00`
        ).getTime();
        let endTime = new Date(`${ticketPrice.endSaleDate} 23:59:59`).getTime();
        // 检查可购买时间
        if (
          ticketPrice.periodSwitch == 1 &&
          currentTimestamp > startTime &&
          currentTimestamp < endTime
        ) {
          // 当前时间在可购买时间段内
          ticketPrice.periodSwitch = 1;
        } else {
          // 当前时间不在可购买时间段内
          ticketPrice.periodSwitch = 0;
        }
      }

      // ticketPrice.singleDayTicketList
      if (
        ticketPrice.singleDayTicketList &&
        ticketPrice.singleDayTicketList.length > 0
      ) {
        ticketPrice.singleDayTicketList.map(
          (item: any, index: number, arr: any) => {
            let currentTimestamp = new Date().getTime();
            if (item.startSaleDate && item.endSaleDate) {
              let startTime = new Date(
                `${item.startSaleDate} 00:00:00`
              ).getTime();
              let endTime = new Date(`${item.endSaleDate} 23:59:59`).getTime();
              // 检查可购买时间
              if (
                item.surplusSwitch == 1 &&
                currentTimestamp > startTime &&
                currentTimestamp < endTime
              ) {
                // 当前时间在可购买时间段内
                item.surplusSwitch = 1;
              } else {
                // 当前时间不在可购买时间段内
                item.surplusSwitch = 0;
              }
            }
          }
        );
      }

      if (
        ticketPrice.groupTicketList &&
        ticketPrice.groupTicketList.length > 0
      ) {
        ticketPrice.groupTicketList.map(
          (item: any, index: number, arr: any) => {
            let currentTimestamp = new Date().getTime();
            if (item.startSaleDate && item.endSaleDate) {
              let startTime = new Date(
                `${item.startSaleDate} 00:00:00`
              ).getTime();
              let endTime = new Date(`${item.endSaleDate} 23:59:59`).getTime();
              // 检查可购买时间
              if (
                item.surplusSwitch == 1 &&
                currentTimestamp > startTime &&
                currentTimestamp < endTime
              ) {
                // 当前时间在可购买时间段内
                item.surplusSwitch = 1;
              } else {
                // 当前时间不在可购买时间段内
                item.surplusSwitch = 0;
              }
              if (
                !otherTicketList.some((s: any) => s.name == item.ticketName)
              ) {
                otherTicketList.push({
                  id: 100 + index, // 自定义id
                  name: item.ticketName,
                });
              }
            } else {
              if (
                !otherTicketList.some((s: any) => s.name == item.ticketName)
              ) {
                otherTicketList.push({
                  id: 100 + index, // 自定义id
                  name: item.ticketName,
                });
              }
            }
          }
        );
      }

      
    }

    let visitor = {};
    if (visitorRes.code === ResponseCode.SUCCESS) {
      visitorRes.data &&
        visitorRes.data.forEach((item: any) => {
          item.checked = false;
        });
      
      visitor =
        visitorRes.data.length > 0
          ? uniqueArray(visitorRes.data, 'visitorIdentity') || ''
          : [];
    }
    let ticketType: number = 2; // 1单日票   2展期票 2, 3其它票
    let ticketTypeName: string = '';
    if (
      ticketPrice.singleDaySwitch === 1 &&
      ticketPrice.isFreeTicket === 0 &&
      ticketPrice.periodSwitch === 0 &&
      ticketPrice.groupSwitch === 0
    ) {
      ticketType = 1;
    }
    if (
      ticketPrice.singleDaySwitch === 0 &&
      ticketPrice.isFreeTicket === 0 &&
      ticketPrice.periodSwitch === 1 &&
      ticketPrice.groupSwitch === 0
    ) {
      ticketType = 2;
    }
    if (
      ticketPrice.singleDaySwitch === 0 &&
      ticketPrice.isFreeTicket === 0 &&
      ticketPrice.periodSwitch === 0 &&
      ticketPrice.groupSwitch === 1
    ) {
      ticketType = 3;
    }
    let otherTicketListResult: any[] = [];

    const otherRes: any[] = await Promise.all([
      ctx.$axios.post('/api/exhibition/list', {
        // locale: langApi,
        selectTimeType: 1,
        industryId: _.get(industry, '[0].id', null), // 行业展会
        regionType: fair.regionType,
        pageNum: 1,
        pageSize: 6,
      }),
    ]);

    const [industryFairRes] = otherRes;

    // 行业展会
    let industryFair: any = {};
    let industryFairList: any = [];
    if (industryFairRes.code === ResponseCode.SUCCESS) {
      industryFair = industryFairRes.data;
      industryFairList = industryFairRes.data.records;
    }

    if (industryFair) {
      let pavilionIdList: any[] = industryFair.records.map(
        (item: any) => item.pavilionId
      );
      const pavilionRes: any[] = await Promise.all([
        ctx.$axios.post('/api/pavilionApi/list', {
          pavilionIdList: pavilionIdList, // 根据展馆id查询展馆信息
        }),
      ]);

      const [pavilionListRes] = pavilionRes;

      // 展馆列表
      if (pavilionListRes.code === ResponseCode.SUCCESS) {
        industryFairList.map((item: any) => {
          pavilionListRes.data.records.map((pavilion: any) => {
            if (item.pavilionId === pavilion.id) {
              item['pavilionName'] = pavilion.pavilionName;
            }
          });
        });
      }
    }

    return {
      otherTicketListResult,
      fairId: id,
      fair,
      ticketPrice,
      otherTicketList,
      visitor,
      ticketType, // 单日票 1  展期票 2, 其它票 3
      attribution: `&copy; <span>${
        _.get(fair, 'pavilionInfo.pavilionName', null) ||
        _.get(fair, 'pavilionInfo.pavilionEnglishName', null)
      }</span>`,
      center: [
        _.get(fair, 'pavilionInfo.latitude', 0),
        _.get(fair, 'pavilionInfo.longitude', 0),
      ],
      // industry,
      // industryId,
      // industryName,
      industryFairList,
    };
  },
  filters: {
    industry(data: string): string {
      try {
        return data.split(',')[0] + '展会';
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return data + '展会';
      }
    },
    logo(value: string): string {
      let parse: any = value;
      if (typeof value === 'string') {
        try {
          parse = JSON.parse(value)[0]
            .replace('http://', 'https://')
            .replace(bucketHost, bucketCdnHost);
        } catch (error) {
          parse = value;
          console.error(error);
        }
      }
      return parse + '!logo';
    },
  },
  data(): any {
    return {
      isLoading: false,
      timeSliceTypeOptions: {
        0: '全天',
        1: '上午',
        2: '下午',
        3: '夜间',
      },
      showVisitor: false, // 参展人弹窗
      cardTypeList: [
        { value: 1, label: '身份证' },
        { value: 2, label: '护照' },
        { value: 3, label: '港澳居民来往内地通行证' },
        { value: 4, label: '台湾居民来往内地通行证' },
      ],
      payType: 'alipay',
      paymentData: {},
      activeStep: 'ticket', // 购买门票步骤 ticket：1门票预订 pay：2.门票支付
      nepcon, // NEPCON 扩展表单数据
      hesuan,
      checkedVisitors: [],
      visitors: {},
      fairId: '',
      isLogin: !!this.$cookies.get('access_token'),
      ticketType: 1, // 单日票 1, 展期票 2, 3 其它票
      singleDayPrice: {},
      groupPrice: {}, // 其它票
      num: 1,
      // payType: -1, // 0-积分兑换 1-PC支付宝支付 2-PC微信支付 3-小程序微信支付
      code: {},
      freeCode: {},
      logo: require('@/assets/icon/ali.png'),
      // ticket: {},
      ticketPrice: {},
      orderData: {},
      fair: {},
      payStatus: 0, // 1-未支付 2-已支付 3-已提交 4-已完成
      visitorLen: 11,
      nameType: 1, // 1公司, 2个人
      visitorsForm: {
        id: '',
        cardType: 1,
        businessCard: '',
        email: '',
        identityCardBack: '',
        identityCardFront: '',
        mobile: '',
        post: '',
        sex: '',
        userCertificates: '',
        visitorIdentity: '',
        visitorName: '',
      },
      ruleForm: {
        corporationName: '',
        companyBusinessLicense: '',
        isKp: false, // 0： 不开发票 1：需要开发票  2：已开发票
        visitors: [
          {
            key: UUID.v4(),
            value: _.cloneDeep(_visitor),
          },
        ],
      },
      dayPrice: [],
      qualificationList: [],
      invoiceRuleForm: {},
      rulesForm: {
        corporationName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
        tel: [{ required: true, message: '公司电话不能为空', trigger: 'blur' }],
        phone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
        ],
        userEmail: [
          { required: true, message: '联系人邮箱不能为空', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
        ],
        cardType: [
          { required: true, message: '证件类型不能为空', trigger: 'blur' },
        ],
        visitorIdentity: [
          { required: true, message: '证件号不能为空', trigger: 'blur' },
        ],
        visitorNameEn: [
          { required: true, message: '姓名拼音不能为空', trigger: 'blur' },
        ],
        visitorName: [
          { required: true, message: '人员姓名不能为空', trigger: 'blur' },
        ],
        sex: [{ required: true, message: '性别不能为空', trigger: 'blur' }],
        post: [{ required: true, message: '职位不能为空', trigger: 'blur' }],
        userCertificates: [
          { required: true, message: '证件照不能为空', trigger: 'change' },
        ],
        identityCardFront: [
          { required: true, message: '证件图片不能为空', trigger: 'change' },
        ],
        identityCardBack: [
          { required: true, message: '证件图片不能为空', trigger: 'change' },
        ],
        businessCard: [
          { required: true, message: '名片为空', trigger: 'change' },
        ],

        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
        ],
      },
      formCorporation: {},
      corporationRules: {
        corporationName: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        companyBusinessLicense: [
          { required: true, message: '营业执照不能为空', trigger: 'change' },
        ],
      },
      privacy: false, // 隐私
      ticketExample: [],
      mapDialogShow: false,
    };
  },
  // isLogin
  watch: {
    login(val) {
      if (val) {
        this.getVisitor();
      }
    },
  },
  computed: {
    ...mapState({
      login: (state: any) => state.global.isLogin,
    }),
    photos(): any[] {
      try {
        const newArr: any[] = [];
        if (this.fair.photos) {
          this.fair.photos.slice(0, 6).map((item: { resourceUrl: any }) => {
            newArr.push(item.resourceUrl);
          });
        }
        return newArr;
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return this.fair.pic;
      }
    },
    tips(): any[] {
      try {
        const { tips } = this.$data.ticketPrice;
        return JSON.parse(tips);
      } catch (error) {
        return [];
      }
    },
    price(): string | number {
      const { ticketType, ticketPrice, singleDayPrice, groupPrice } =
        this.$data;
      if (ticketType === 1) {
        // 单日票
        return singleDayPrice?.singleDayPrice || '';
      } else if (ticketType === 2) {
        // 展期票
        return ticketPrice?.periodPrice || '';
      } else if (ticketType >= 100) {
        // 其它票
        return groupPrice?.price || '';
      }
      return 0;
    },
    // 服务费
    serviceFee(): string | number {
      const { ticketType, ticketPrice, singleDayPrice } = this.$data;
      if (ticketType === 1) {
        // 单日票
        return singleDayPrice?.serviceFee || '';
      } else if (ticketType === 2) {
        // 展期票
        return ticketPrice?.serviceFee || '';
      } else if (ticketType >= 100) {
        // 其它票
        return ticketPrice?.serviceFee || '';
      }
      return 0;
    },
    ticketPrice2(): string | number {
      const p = Math.subtract(this.price, this.serviceFee);
      const taxP = Math.multiply(p, this.taxRate);

      return Math.subtract(p, taxP);
    },
    // 税率
    taxRate(): string | number {
      const { ticketType, ticketPrice, singleDayPrice } = this.$data;
      if (ticketType === 1) {
        // 单日票
        return singleDayPrice?.taxRate || '';
      } else if (ticketType === 2) {
        // 展期票
        return ticketPrice?.taxRate || '';
      } else if (ticketType === 3) {
        // 展期票
        return ticketPrice?.taxRate || '';
      }
      return 0;
    },
    // 总价
    countPrice(): string | number {
      return +Math.multiply(this.price, this.ruleForm.visitors.length)?.toFixed(
        2
      );
    },
    // 税和服务费
    otherPrice(): string | number {
      // return parseInt(this.countPrice * 0.17 + '');
      return this.countPrice?.toFixed(2);
    },
    // 税价
    taxPrice(): string | number {
      return +Math.multiply(this.price, this.taxRate);
    },
    // 促销和优惠
    discountsPrice(): string {
      const len = this.ruleForm.visitors.length || 1;
      let discount = 0.05;
      if (len >= 2 && len <= 5) {
        discount = 0.1;
      } else if (len > 5) {
        discount = 0.15;
      }
      return Math.multiply(this.countPrice, discount)?.toFixed(2);
    },
  },
  created() {
    // if (this.detail.isFreeTicket === 1 && this.ticket.status === 1) {
    //   this.payType = 0;
    // }
    // if (this.payType > 0) {
    //   this.getPayStatus();
    // }
    if (process.client) {
      const ticketForm = sessionStorage.getItem('ticketForm');
      const invoiceRuleForm = sessionStorage.getItem('invoiceRuleForm') || '{}';
      const ruleForm =
        ticketForm ||
        JSON.stringify({
          visitors: [
            {
              key: UUID.v4(),
              value: _.cloneDeep(_visitor),
            },
          ],
        });
      try {
        this.ruleForm = JSON.parse(ruleForm as any);
        this.invoiceRuleForm = JSON.parse(invoiceRuleForm as any);
      } catch (error) {
        console.error(error);
      }
      if (ticketForm) {
        sessionStorage.removeItem('ticketForm');
        sessionStorage.removeItem('invoiceRuleForm');
      }
    }
    const token = this.$cookies.get('access_token');
    if (token) {
      this.queryVisitors();
      this.getTicketExample();
    }
  },
  mounted() {},
  methods: {
    xss,
    getContent,
    moment,
    isUsable(item: any) {
      // ticketPrice.businessCardSwitch === 1
      let result = false;
      // 检查身份证
      if (
        (this.ticketPrice.identityCardSwitch === 1 && !item.identityCardBack) ||
        (this.ticketPrice.identityCardSwitch === 1 && !item.identityCardFront)
      )
        result = true;
      // 检查证件照
      if (
        this.ticketPrice.userCertificatesSwitch === 1 &&
        !item.userCertificates
      )
        result = true;
      // 检查名片
      if (this.ticketPrice.businessCardSwitch === 1 && !item.businessCard)
        result = true;
      return result;
    },
    back() {
      this.activeStep = 'ticket';
    },
    handleDetail(id: number) {
      window.open(`/ticket/${id}.html`);
    },
    getAddress() {
      this.mapDialogShow = true;
    },
    // this.fairId
    async getTicketExample() {
      const res: any = await this.$axios.post(`/api/exhibitionApi/get/`, {
        id: this.fairId,
      });

      if (res.code === ResponseCode.SUCCESS && res.data) {
        this.ticketExample = JSON.parse(res.data.ticketExample);
      }
    },
    numToText(value: number, list: any, before: any, after: any) {
      // 从数组中返回对应内容，value为查询的值，list为数组，before为查询的字段名，after为返回值的字段名（不传after时返回匹配到的整个元素）
      if (list && before && (value || value === 0)) {
        for (const i in list) {
          if (list[i][before] === value) {
            if (after) return list[i][after];
            else return _.cloneDeep(list[i]);
          }
        }
      } else return '';
    },
    // goBack(){
    //   // this.$router.back();
    //   this.$router.go(-1);
    // },
    getLaberFont(type: number) {
      if (type == 1) {
        return '身份证正面';
      }
      if (type == 2) {
        return '护照正面';
      }
      if (type == 3) {
        return '港澳居民来往内地通行证正面';
      }
      if (type == 4) {
        return '台湾居民来往内地通行证正面';
      }
    },
    getLaberback(type: number) {
      if (type == 1) {
        return '身份证反面';
      }
      if (type == 2) {
        return '护照反面';
      }
      if (type == 3) {
        return '港澳居民来往内地通行证反面';
      }
      if (type == 4) {
        return '台湾居民来往内地通行证反面';
      }
    },
    togglePayment(type: string) {
      this.payType = type;
      if (type === 'alipay') {
        this.$data.showBtn = true;
      }
    },
    handleMoreVisitors() {
      if (this.visitorLen === 11) {
        this.visitorLen = this.visitors.length;
      } else {
        this.visitorLen = 11;
      }
    },
    handleTicketClick(singleDay: Record<string, any>) {
      if (singleDay.surplusSwitch === 0) return;
      this.singleDayPrice = singleDay || {};
    },
    handleGroupTicketClick(singleDay: Record<string, any>) {
      if (singleDay.surplusSwitch === 0) return;
      this.groupPrice = singleDay || {};
    },
    async queryVisitors() {
      
      if (!this.fairId) return;
      const res: any = await this.$axios.get(
        `/api/pay/queryOrderStatusByExhibitionId/${this.fairId}`
      );
      
      if (res.code === ResponseCode.SUCCESS && res.data) {
        this.visitors = res.data;
        const ruleForm = _.cloneDeep(this.ruleForm); // 深拷贝
        ruleForm.corporationName = res.data?.corporationName || '';
        ruleForm.companyBusinessLicense =
          res.data?.companyBusinessLicense || '';
        const visitorList: any[] = [];
        res.data.visitorsVos.forEach((visitors: Record<string, any>) => {
          visitorList.push({
            key: UUID.v4(),
            value: visitors,
          });
        });
        ruleForm.visitors = visitorList;
        this.ruleForm = ruleForm;
        if (res.data.isKp === 1) {
          this.ruleForm.isKp = true;
        } else {
          this.ruleForm.isKp = true;
        }
        if (res.data.invoice) {
          this.invoiceRuleForm = _.get(res, 'data.invoice', {});
        }
      }
      
    },
    // querySearchVisitor(queryString: string, cb: (res: string) => void) {
    //   const restaurants = this.visitor.map((item: { visitorName: string }) => ({
    //     value: item.visitorName,
    //     data: item,
    //   }));
    //   const results = queryString
    //     ? restaurants.filter(this.createFilter(queryString))
    //     : restaurants;
    //   // 调用 callback 返回建议列表的数据
    //   cb(results);
    // },
    // createFilter(queryString: string) {
    //   return (restaurant: { value: string }) => {
    //     return (
    //       restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
    //       0
    //     );
    //   };
    // },
    // hanleSelectVisitor(data: Record<string, any>, index: number) {
    //   this.checkedVisitors.push(data.data.visitorIdentity); // 选中观展人
    //   this.ruleForm.visitors.forEach(
    //     (item: Record<string, any>, idx: number) => {
    //       if (idx === index) {
    //         item.value = data.data;
    //       }
    //     }
    //   );
    // },

    addPersonnel() {
      this.$refs.refForm.validate((valid: ElForm) => {
        if (valid) {
          let idOther = (item: any) => {
            if (!this.visitorsForm.id) return true;
            if (item.id != this.visitorsForm.id) {
              return true;
            } else {
              return false;
            }
          };

          // 检查邮箱是否存在
          // let checkEmail = this.visitor.some((item: any) => item.email === this.visitorsForm.email && idOther(item))

          // if (checkEmail) {
          //   message.call(this, {
          //     delay: 3000,
          //     title: `你的参展人列表已存在邮箱 ${this.visitorsForm.email}`,
          //     type: 'error',
          //   });
          //   return;
          // }
          // 检查手机号是否存在
          // let checkMobile = this.visitor.some((item: any) => item.mobile === this.visitorsForm.mobile && idOther(item))
          // if (checkMobile) {
          //   message.call(this, {
          //     delay: 3000,
          //     title: `你的参展人列表已存在手机号 ${this.visitorsForm.mobile}`,
          //     type: 'error',
          //   });
          //   return;
          // }

          this.getPersonnel();
        }
      });
    },
    async getPersonnel() {
      const uid = _.get(this.$store, 'state.global.user.userId', 0);
      const params: Record<string, any> = { uid, ...this.visitorsForm };

      this.isLoading = true;
      // /visitor/update
      let apiUrl = params.id ? '/api/visitor/update' : '/api/visitor/create';
      const res = await this.$axios.post(`${apiUrl}`, params);
      if (res.code === '1') {
        this.$message.success('操作成功');
        this.isLoading = false;
        this.getVisitor();
        const form = this.$refs['refForm'] as ElForm;
        form.resetFields();
        this.showVisitor = false;
      } else {
        this.$message.success('操作失败');
      }
    },
    async getVisitor() {
      const res = await this.$axios.post(`/api/visitor/list-by-current`);
      if (res.code === '1') {
        res.data &&
          res.data.forEach((item: any) => {
            item.checked = false;
          });
        this.visitor =
          res.data.length > 0
            ? uniqueArray(res.data, 'visitorIdentity') || ''
            : [];
      }
    },

    fillVisitor(check: any, data: any) {
      
      this.ruleForm.visitors.forEach((item: any, index: number) => {
        if (item.value.visitorIdentity === '') {
          this.ruleForm.visitors.splice(index, 1);
        }
      });
      if (check) {
        let value = {};
        value = {
          id: data.id,
          visitorName: data.visitorName,
          sex: data.sex,
          mobile: data.mobile,
          email: data.email,
          cardType: data.cardType, // 1身份证 2护照 3港澳居民来往内地通行证 4台湾居民来往内地通行证
          visitorIdentity: data.visitorIdentity,
          post: data.post,
          userCertificates: data.userCertificates, // 证件照
          identityCardFront: data.identityCardFront,
          identityCardBack: data.identityCardBack,
          businessCard: data.businessCard,
          department: data.department, // 部门
          companyTypes: data.companyTypes || [], // 公司所属类型
          companyService: data.companyService || [], // 公司所属类型
          products: data.products || [], // 公司所属类型
        };
        if (value) {
          this.ruleForm.visitors.push({
            key: UUID.v4(),
            value,
          });
        }
      } else {
        this.ruleForm.visitors.forEach((item: any, index: number) => {
          if (data.visitorIdentity === item.value.visitorIdentity) {
            this.ruleForm.visitors.splice(index, 1);
          }
        });
      }
      
      // 选中时去掉选中,并移除观展人信息
    },
    ticketTypeChange(type: number, name: string = '') {
      this.ticketType = type;
      this.ticketTypeName = name;
      // 单日票
      if (type === 1 && _.isEmpty(this.singleDayPrice)) {
        try {
          this.singleDayPrice =
            this.ticketPrice?.singleDayTicketList.find(
              (item: { surplusSwitch: number }) => item.surplusSwitch === 1
            ) || {};
        } catch (error) {
          this.singleDayPrice = {};
        }
      }
      // 其它票
      if (type >= 100) {
        this.groupPrice = {};
        try {
          this.otherTicketListResult = this.ticketPrice.groupTicketList.filter(
            (i: any) => i.ticketName == this.ticketTypeName
          );
          // 自动选中对应门票类型下的一个门票
          this.groupPrice =
            this.ticketPrice?.groupTicketList.find(
              (item: any) => item.ticketName == name && item.surplusSwitch === 1
            ) || {};
        } catch (error) {
          this.groupPrice = {};
        }
        
      }
    },
    // 删除表格的观展人
    // delVistors(key: string){
    //   this.visitor.forEach((item: any , index:number)=>{
    //     if(key===item.visitorIdentity){
    //       this.visitor.splice(index, 1);
    //     }
    //   })

    // },
    handleEdit(item: any) {
      
      const { $login, login } = this;
      if (!login) {
        return $login.show();
      }

      for (let i in this.visitorsForm) {
        this.visitorsForm[i] = item[i];
        // this.setData({
        //   [`visitorsForm.${i}`]: item[i]
        // })
      }

      this.showVisitor = true;
      // this.$refs.refForm.clearValidate();
    },
    delVistors(id: number) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.delvisitorList(id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    async delvisitorList(id: number) {
      const res = await this.$axios.post(`/api/visitor/delete`, { id });
      if (res.code === '1') {
        this.$store.commit('user/setVisitorInfo', {});
        this.$message.success('删除成功');
        this.getVisitor();
      }
    },

    // 删除观展人
    // delVisitor(key: string) {
    //   let cardNo = '';
    //   const idx = this.ruleForm.visitors.findIndex(
    //     (item: Record<string, any>) => {
    //       cardNo = item.value.visitorIdentity;
    //       return item.key === key;
    //     }
    //   );
    //   if (idx > -1) {
    //     this.ruleForm.visitors.splice(idx, 1);
    //   }

    //   const idx2 = this.checkedVisitors.indexOf(cardNo);
    //   if (idx2 > -1) {
    //     this.checkedVisitors.splice(idx2, 1);
    //   }
    // },
    // 增加观展人
    // addVisitor() {
    //   this.ruleForm.visitors.push({
    //     key: UUID.v4(),
    //     value: _.cloneDeep(_visitor),
    //   });
    // },
    handleName() {
      this.nameType = this.nameType == 1 ? 2 : 1;
    },
    addVisitor() {
      const { $login, login } = this;
      if (!login) {
        return $login.show();
      }
      this.handleReset();
      // 国外展会默认选中"护照"
      if (this.fair.regionType == 1) {
        this.visitorsForm.cardType = 2;
      }
      //
      this.showVisitor = true;
      this.$refs['refForm'].clearValidate();
    },
    closeVisitor() {
      this.handleReset();

      this.showVisitor = false;
    },
    handleReset() {
      for (let i in this.visitorsForm) {
        if (i != 'cardType') this.visitorsForm[i] = '';
      }
      // this.$refs["refForm"].resetFields();
      // this.$refs["refForm"].clearValidate();
    },
    // 提交请求
    async submitData() {
      if (this.ruleForm && this.ruleForm.visitors.length === 0) {
        message.call(this, {
          delay: 3000,
          title: '请勾选参展人员',
          type: 'error',
        });
        return;
      }
      if (!this.privacy) {
        // console.log(
        //   this.$refs.privacy.$el.getBoundingClientRect().top,
        //   window.pageYOffset
        // ); // .$el.offsetTop
        const top =
          this.$refs.privacy.$el.getBoundingClientRect().top +
          window.pageYOffset -
          document.documentElement.clientHeight / 2;
        window.scrollTo({ top });
        // return this.$message.error(
        //   '您未同意隐私条例, 无法使用我们的产品和服务.'
        // );
        message.call(this, {
          delay: 3000,
          title: '请勾选隐私条例,无法使用我们的产品和服务.',
          type: 'error',
        });
        return;
      }
      const { corporationName, companyBusinessLicense } = this.formCorporation;
      const visitors: any = [];
      this.ruleForm.visitors.forEach((item: any) => {
        
        const {
          companyTypesOther,
          companyServiceOther,
          productsOther,
          ...data
        } = item.value;
        // delete item.value.cardType;
        companyTypesOther && data.companyTypes.push(companyTypesOther);
        companyServiceOther && data.companyService.push(companyServiceOther);
        productsOther && data.products.push(productsOther);
        visitors.push({
          ...data,
        });
      });
      const params: Record<string, any> = {
        corporationName,
        companyBusinessLicense,
        osType: 2, // 1:h5  2:PC  3:小程序
        visitors,
        ticketType: this.ticketType >= 100 ? 3 : this.ticketType, // 1展期票 2单日票 3其它票
        exhibitionId: this.fair.exhibitionId,
        promotionLink: window.location.href,
      };
      // 单日票
      if (this.ticketType === 1) {
        params.singleDayTicketId = this.singleDayPrice?.id;
      }
      // 其它票
      if (this.ticketType >= 100) {
        params.singleDayTicketId = this.groupPrice?.id;
      }
      
      // return false

      const res: any = await this.$axios.post(`/api/pay/saveVisitors`, params);
      if (res.code === '1') {
        
        if (this.ticketPrice.isFreeTicket === 0 && this.price > 0) {
          this.paymentData = {
            id: this.fair.exhibitionId,
            singleId: this.singleDayPrice?.id,
            type: this.ticketType,
            num: this.ruleForm.visitors.length,
            mode: 'ticket',
            orderNo: res.data.orderNo,
          };
          this.orderNo = res.data.orderNo;
          this.orderId = res.data.id;
          this.activeStep = 'pay';
          // 埋点
          window.trace?.upload({
            module: TraceCode.MO_FAIR_TICKET,
            bizId: this.$route.params.id,
            bizOrderId: res.data.orderNo,
            bizOrderPayType: TraceCode.PAY,
            callbackCode: TraceCode.CC_SUBMIT_TICKET,
            success: TraceCode.SUCCESS,
          });
        } else {
          this.$router.push(
            `/result/success/?id=${this.fairId}&orderNo=${this.orderNo}`
          );
          // 埋点
          window.trace?.upload({
            module: TraceCode.MO_FAIR_TICKET,
            bizId: this.$route.params.id,
            bizOrderId: res.data.orderNo,
            bizOrderPayType: TraceCode.FREE,
            callbackCode: TraceCode.CC_SUBMIT_TICKET,
            success: TraceCode.SUCCESS,
          });
          // this.$confirm(
          //   `展会将于${this.fair.lunchTime}举办，请携带身份证原件刷卡入场或至现场服务台换领纸质进馆证！预祝观展顺利！客服联系电话  400-099-0060`,
          //   '预订成功',
          //   {
          //     confirmButtonText: '确认',
          //     roundButton: true,
          //     type: 'success',
          //     center: true,
          //   }
          // )
          //   .then(() => {
          //     this.$router.push(`/exhibition/${this.fair.exhibitionId}.html`);
          //   })
          //   .catch(() => {
          //   });
        }
      } else {
        message.call(this, {
          delay: 3000,
          title: res.errorMessage || res.message || '提交失败',
          type: 'error',
        });
        // 埋点
        window.trace?.upload({
          module: TraceCode.MO_FAIR_TICKET,
          bizId: this.$route.params.id,
          callbackCode: TraceCode.CC_SUBMIT_TICKET,
          success: TraceCode.FAIL,
        });
      }
    },
    // 提交表单
    async handleSubmit() {
      
      // let emailList:any = []
      // let mobileList:any = []
      // this.ruleForm.visitors.map((item:any) => {
      //   emailList.push(item.value.email)
      //   mobileList.push(item.value.mobile)
      // })
      // //
      //   message.call(this, {
      //     });
      //     return false
      // }
      // if (Array.from(new Set(mobileList)).length < mobileList.length){
      //   message.call(this, {
      //       delay: 3000,
      //       title: '参展人手机号不能重复',
      //       type: 'error',
      //     });
      //   return false
      // }
      // return false
      this.ruleForm.visitors.forEach((item: any, index: number) => {
        if (item.value.visitorIdentity === '') {
          this.ruleForm.visitors.splice(index, 1);
        }
      });
      await this.$store.commit('ticket/setTicketInfo', this.ruleForm.visitors);
      const token = this.$cookies.get('access_token');
      if (!token) {
        await this.$store.commit(
          'user/setOrigins',
          location.origin + this.$route.fullPath
        );
        await this.$store.commit('user/setAction', 2);
        await this.$store.commit('user/setIndustryId', this.fair.industry1);
        this.toLogin();
        return;
      }
      // 非免费
      // if (this.ticketPrice.isFreeTicket === 0) {
      // 1 单日票
      if (this.ticketType === 1 && !this.singleDayPrice?.id) {
        message.call(this, {
          delay: 3000,
          title: '请选择门票价格',
          type: 'error',
        });
        return;
      }
      // 2 展期票
      if (this.ticketType === 2 && this.ticketPrice.periodSwitch === 0) {
        message.call(this, {
          delay: 3000,
          title: '请选择门票',
          type: 'error',
        });
        return;
      }
      // 其它票
      if (this.ticketType >= 100 && !this.groupPrice.hasOwnProperty('id')) {
        
        // let dddb= this.ticketPrice.groupTicketList
        // if (this.ticketTypeName) {
        // }

        message.call(this, {
          delay: 3000,
          title: '请选择门票',
          type: 'error',
        });
        return;
      }
      // }
      const $orderForm = this.$refs.formCorporation as ElForm;
      $orderForm.validate((valid) => {
        if (valid) {
          this.submitData();
        } else {
          message.call(this, {
            delay: 3000,
            title: '请填写公司完整信息。',
            type: 'error',
          });
        }
      });
    },
    toLogin() {
      this.$login.show();
      // const { fullPath } = this.$route;
      // this.$router.push({
      //   path: `/login?redirect=${encodeURIComponent(fullPath)}`,
      // });
      sessionStorage.setItem('ticketForm', JSON.stringify(this.ruleForm));
      sessionStorage.setItem(
        'invoiceRuleForm',
        JSON.stringify(this.invoiceRuleForm)
      );
    },
    handleBusinessSuccess(res: any) {
      const $orderForm = this.$refs.formCorporation as ElForm;
      this.setData({
        'formCorporation.companyBusinessLicense': res,
      });
      $orderForm.validateField('companyBusinessLicense');
    },
    // 图片上传成功
    handleSuccess(
      res: any,
      _file: ElUploadInternalFileDetail,
      _fileList: ElUploadInternalFileDetail[],
      prop: string
    ) {
      const $orderForm = this.$refs.refForm as ElForm;
      const field = prop.split('.')[3];
      this.setData({
        [`visitorsForm.${prop}`]: res,
      });
      $orderForm.validateField(field);
    },
    querySearchAsync: _.debounce(async function (
      str: string,
      cb: (val: any[]) => void
    ) {
      // @ts-ignore
      const { $axios, login, $login } = this;
      if (!login) {
        return $login.show();
      }
      let data = [];
      if (str && str.trim()) {
        const res: any = await $axios.post(
          '/api/companyApi/keyWord',
          str.trim(),
          {
            headers: {
              'Content-Type': 'text/plain',
            },
          }
        );
        data = res.data.map((r: string) => ({ value: r }));
      } else {
        data = [];
      }
      cb(data);
    },
    300),
  },
  head(): Record<string, any> {
    const {
      startTime = '',
      endTime = '',
      exhibitionName = '',
      industry = '',
      organizer = '',
      // pic,
      pavilionInfo = {},
    } = this.$data.fair;
    const {
      areaName = '',
      regionName = '',
      pavilionName = '',
    } = (pavilionInfo || {}) as Record<string, any>;
    const industrys = industry?.split(',');
    const industryName = _.get(industrys, '[0]', '');
    return {
      title: `${startTime.slice(0, 4)}${exhibitionName}_门票预订-聚展`,
      meta: [
        {
          name: 'keywords',
          content: `${areaName}${industryName}展,${regionName}${industryName}展`,
        },
        {
          name: 'description',
          content: `展会时间:${startTime}至${endTime},展会地点:${pavilionName},主办单位:${organizer},聚展网提供展位预订,展会门票,展会行程,展会搭建等服务`,
        },
      ],
    };
  },
});
